import React, { useState, useEffect, useContext, useCallback } from "react"
import { useForm } from "react-hook-form"
import { axiosProtected } from "../../client"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"

import { Address } from "../../components/cart/Address"
import { useDropzone } from "react-dropzone"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import PageContext from "../../context/PageContext"
import { Spin } from "../../components/elements/Spin"
import { navigate } from "gatsby-link"

const MujUcetKontaktPage = () => {
  const [authContext] = useContext(PageContext)

  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState()
  const [addressData, setAddressData] = useState()

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")))
    // eslint-disable-next-line
  }, [])

  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    aspect: 1 / 1,
  })
  const [src, setSrc] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  const [image, setImage] = useState(null)

  const onChange = () => {}
  const resetResize = () => {
    setSrc(null)
    setFileName(null)
    setCroppedImageUrl(null)
    setImage(null)
    setImage(null)
  }

  const onDrop = useCallback(accepted => {
    if (accepted && accepted.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        return setSrc(reader.result)
      })
      reader.readAsDataURL(accepted[0])
      setFileName(accepted[0].name)
    }
  }, [])

  const onImageLoaded = img => {
    setImage(img)
  }
  const onCropComplete = crop => {
    makeClientCrop(crop)
  }
  const onCropChange = crop => {
    setCrop(crop)
  }
  const makeClientCrop = async crop => {
    if (image && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(image, crop, "newFile.jpeg")
      setCroppedImageUrl(croppedImageUrl)
    }
  }
  const getCroppedImg = (img, crop, fileName) => {
    const canvas = document.createElement("canvas")
    const scaleX = img.naturalWidth / img.width
    const scaleY = img.naturalHeight / img.height
    canvas.width = 500
    canvas.height = 500
    const ctx = canvas.getContext("2d")
    ctx.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    )
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty")
          return
        }
        blob.name = fileName
        let fileUrl
        window.URL.revokeObjectURL(fileUrl)
        fileUrl = window.URL.createObjectURL(blob)
        resolve(fileUrl)
      }, "image/jpeg")
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: onDrop,
  })
  const { register, handleSubmit, setValue, formState } = useForm()

  let errors = formState.errors

  useEffect(() => {
    if (image) {
      makeClientCrop(crop)
    }
    // eslint-disable-next-line
  }, [image])

  useEffect(() => {
    !authContext.isLoggedIn && navigate("/prihlaseni")
    // eslint-disable-next-line
  }, [authContext])

  const getAddress = useCallback(async () => {
    try {
      const res = await axiosProtected().post("users_data/users_settings/")
      if (res.data) {
        await setAddressData(res.data.addresses)
      }
    } catch (err) {
      console.log(err)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getAddress()
  }, [getAddress])

  const onSubmit = async data => {
    const formData = new FormData()
    if (croppedImageUrl) {
      let blob = await fetch(croppedImageUrl).then(r => r.blob())
      const croppedFile = new File([blob], fileName, {
        type: "image/jpeg",
        quality: 0.95,
      })

      formData.append("file", croppedFile)
    }

    for (let key in data) {
      formData.append(key, data[key])
    }

    setLoading(true)
    await axiosProtected()
      .post("users_data/update_settings/", formData)
      .then(res => {
        authContext.login(res.data.accessToken)
        getAddress()
        resetResize()
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nákupní košík</title>
      </Helmet>

      <div className="  ">
        <div>
          <div className="divide-y divide-gray-200 lg:divide-y-0 lg:divide-x max-w-7xl mx-auto">
            <div className="divide-y divide-gray-200 lg:col-span-9">
              <div className="px-3 lg:px-8 py-5">
                <div className="grid grid-cols-2  gap-5 ">
                  <div className="col-span-2">
                    <h3 className="text-lg text-gray-500 md:text-xl mx-auto border-b border-gray-200 pb-2">
                      <span className="font-bold">Nastavení</span> uživatele
                    </h3>
                    <div className="bg-white px-4 py-6   sm:p-6 rounded-md my-2">
                      <div className="grid lg:grid-cols-2 hidden">
                        <div className="max-w-md">
                          <div
                            {...getRootProps()}
                            className="cursor-pointer mb-5 mt-2 border-2 border-dashed rounded border-gray-300 hover:border-green-500 relative text-gray-500  hover:text-gray-800"
                          >
                            <input
                              {...getInputProps({ onChange })}
                              className=" relative block opacity-0 w-full h-full p-10 z-50"
                            />
                            <div className="text-center p-6 pt-4   top-0 right-0 left-0 m-auto">
                              <svg
                                className="inline  h-8 w-8 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <h4 className="grid">
                                <button className="bg-gray-200 py-1 px-4  rounded focus:outline-none ">
                                  vyberte obrázek
                                </button>
                                <span className="hidden md:inline ml-2">
                                  nebo jej přetáhněte sem
                                </span>
                              </h4>
                            </div>
                          </div>
                          <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={onImageLoaded}
                            onComplete={onCropComplete}
                            onChange={onCropChange}
                          />
                        </div>
                        <div className="relative">
                          {croppedImageUrl && (
                            <>
                              <img
                                alt="Crop"
                                style={{
                                  maxHeight: "150px",
                                  margin: "auto",
                                  display: "block",
                                }}
                                src={croppedImageUrl}
                                className="rounded-full mb-5"
                              />
                              <div>
                                <button
                                  type="button"
                                  onClick={() => resetResize()}
                                  className="px-3 py-1 bg-gray-100 hover:bg-gray-200 rounded-md text-gray-600 absolute top-2 right-2"
                                >
                                  Zrušit
                                </button>
                              </div>
                            </>
                          )}

                          {userData?.data?.img && !croppedImageUrl && (
                            <>
                              <img
                                alt="Crop"
                                style={{
                                  maxHeight: "150px",
                                  margin: "auto",
                                  display: "block",
                                }}
                                src={userData?.data?.img}
                                className="rounded-full"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className=" pb-8 pt-4"
                      >
                        <Address
                          register={register}
                          errors={errors}
                          setValue={setValue}
                          addressData={addressData}
                        />

                        <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 "></div>
                        {/* <label htmlFor="street_address" className="block text-sm mt-3 font-medium text-gray-700">
                    Přílohy
                  </label>
                  <FileUpload uploadId={uploadId} setModal={setModal} module={"messageboard"} />
              */}

                        <div className="mt-8">
                          {loading ? (
                            <button
                              disabled
                              className="bg-gray-200 text-gray-800 font-bold py-4 px-4 w-full rounded cursor-default"
                            >
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              <span>Odesílání...</span>
                            </button>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="bg-gray-700 text-white font-bold py-4 px-20  rounded hover:bg-gray-600"
                              >
                                Uložit změny
                              </button>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spin />}
    </>
  )
}

export default MujUcetKontaktPage
